import { HomeModule } from '@/pages/suggested-for-you';
import { House } from 'lucide-react';

import i18n from '@/lang/index';
import { Route } from '@/types/routes';

export const routes: Route[] = [
  {
    sidebar: {
      title: i18n.t('pages.suggestedForYou.sidebar.title'),
    },
    icon: House,
    route: 'dashboard/suggested-for-you',
    component: HomeModule,
  },
];
