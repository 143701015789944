import { moduleApi } from '@/api/module';
import { GlobalError } from '@/globals/errors';
import {
  configureStore,
  isRejected,
  Middleware,
  MiddlewareAPI,
  UnknownAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from '../api/auth';
import { globalSlice } from './modules/global';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    if ((action.payload as { status?: number })?.status === 401) {
      api.dispatch(globalSlice.actions.setError(GlobalError.SESSION_EXPIRED));
      api.dispatch(authApi.endpoints.logoutUser.initiate() as unknown as UnknownAction);
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [moduleApi.reducerPath]: moduleApi.reducer,
    global: globalSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([rtkQueryErrorLogger, authApi.middleware, moduleApi.middleware]),
});

setupListeners(store.dispatch);
export type IRootState = ReturnType<typeof store.getState>;
